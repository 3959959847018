var FETCH_SERVICES = 'FETCH_SERVICES';
var SERVICES_ERROR = 'SERVICES_ERROR';
var EXTENSION_IOT = 'EXTENSION_IOT';
var EXTENSION_SEA_FOOD = 'EXTENSION_SEA_FOOD';
var RECEIVED_SERVICES_SUCCESS = 'RECEIVED_SERVICES_SUCCESS';
var RECEIVED_SERVICES_ERROR = 'RECEIVED_SERVICES_ERROR';
export default {
  FETCH_SERVICES: FETCH_SERVICES,
  SERVICES_ERROR: SERVICES_ERROR,
  EXTENSION_IOT: EXTENSION_IOT,
  EXTENSION_SEA_FOOD: EXTENSION_SEA_FOOD,
  RECEIVED_SERVICES_SUCCESS: RECEIVED_SERVICES_SUCCESS,
  RECEIVED_SERVICES_ERROR: RECEIVED_SERVICES_ERROR
};