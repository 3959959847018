// Items
var FETCH_INVENTORY_ITEMS = "FETCH_INVENTORY_ITEMS";
var FETCH_LIGHT_WEIGHT_INVENTORY_ITEMS = "FETCH_LIGHT_WEIGHT_INVENTORY_ITEMS";
var RECEIVE_INVENTORY_ITEMS = "RECEIVE_INVENTORY_ITEMS";
var RECEIVE_INVENTORY_ITEMS_FAILED = "RECEIVE_INVENTORY_ITEMS_FAILED";
var CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES";
var SELECT_INVENTORY_ITEM = "SELECT_INVENTORY_ITEM";
var UNSELECT_INVENTORY_ITEM = "UNSELECT_INVENTORY_ITEM";
var CLEAR_SELECTED_INVENTORY_ITEMS = "CLEAR_SELECTED_INVENTORY_ITEMS"; // Pending items

var FETCH_PENDING_ITEMS = "FETCH_PENDING_ITEMS";
var RECEIVE_PENDING_ITEMS = "RECEIVE_PENDING_ITEMS";
var RECEIVE_PENDING_ITEMS_FAILED = "RECEIVE_PENDING_ITEMS_FAILED";
var RESET_PENDING_ITEMS = "RESET_PENDING_ITEMS"; // Global items

var FETCH_GLOBAL_ITEMS = "FETCH_GLOBAL_ITEMS";
var RECEIVE_GLOBAL_ITEMS = "RECEIVE_GLOBAL_ITEMS";
var RECEIVE_GLOBAL_ITEMS_FAILED = "RECEIVE_GLOBAL_ITEMS_FAILED";
var CLEAR_GLOBAL_ITEMS_STATE = "CLEAR_GLOBAL_ITEMS_STATE"; // Consolidate

var CONSOLIDATE = "CONSOLIDATE";
var RECEIVE_CONSOLIDATE = "RECEIVE_CONSOLIDATE";
var RECEIVE_CONSOLIDATE_FAILED = "RECEIVE_CONSOLIDATE_FAILED";
var RESET_CONSOLIDATE = "RESET_CONSOLIDATE";
var CONSOLIDATE_DASHBOARD = "CONSOLIDATE_DASHBOARD"; // consolidate in dashboard app

var RECEIVE_CONSOLIDATE_DASHBOARD = "RECEIVE_CONSOLIDATE_DASHBOARD";
var RECEIVE_CONSOLIDATE_DASHBOARD_FAILED = "RECEIVE_CONSOLIDATE_DASHBOARD_FAILED";
var CLEAR_GLOBAL_ITEMS_ERRORS = "CLEAR_GLOBAL_ITEMS_ERRORS";
var FETCH_DEPLOYMENTS = "FETCH_DEPLOYMENTS";
var RECEIVE_DEPLOYMENTS = "RECEIVE_DEPLOYMENTS";
export default {
  // Inventory
  FETCH_INVENTORY_ITEMS: FETCH_INVENTORY_ITEMS,
  FETCH_LIGHT_WEIGHT_INVENTORY_ITEMS: FETCH_LIGHT_WEIGHT_INVENTORY_ITEMS,
  RECEIVE_INVENTORY_ITEMS: RECEIVE_INVENTORY_ITEMS,
  RECEIVE_INVENTORY_ITEMS_FAILED: RECEIVE_INVENTORY_ITEMS_FAILED,
  CLEAR_ERROR_MESSAGES: CLEAR_ERROR_MESSAGES,
  SELECT_INVENTORY_ITEM: SELECT_INVENTORY_ITEM,
  UNSELECT_INVENTORY_ITEM: UNSELECT_INVENTORY_ITEM,
  CLEAR_SELECTED_INVENTORY_ITEMS: CLEAR_SELECTED_INVENTORY_ITEMS,
  // Pending
  FETCH_PENDING_ITEMS: FETCH_PENDING_ITEMS,
  RECEIVE_PENDING_ITEMS: RECEIVE_PENDING_ITEMS,
  RECEIVE_PENDING_ITEMS_FAILED: RECEIVE_PENDING_ITEMS_FAILED,
  RESET_PENDING_ITEMS: RESET_PENDING_ITEMS,
  // Consolidate
  CONSOLIDATE: CONSOLIDATE,
  RECEIVE_CONSOLIDATE: RECEIVE_CONSOLIDATE,
  RECEIVE_CONSOLIDATE_FAILED: RECEIVE_CONSOLIDATE_FAILED,
  RESET_CONSOLIDATE: RESET_CONSOLIDATE,
  // Global
  FETCH_GLOBAL_ITEMS: FETCH_GLOBAL_ITEMS,
  RECEIVE_GLOBAL_ITEMS: RECEIVE_GLOBAL_ITEMS,
  RECEIVE_GLOBAL_ITEMS_FAILED: RECEIVE_GLOBAL_ITEMS_FAILED,
  CLEAR_GLOBAL_ITEMS_STATE: CLEAR_GLOBAL_ITEMS_STATE,
  // Consolidate in dashboard app
  CONSOLIDATE_DASHBOARD: CONSOLIDATE_DASHBOARD,
  RECEIVE_CONSOLIDATE_DASHBOARD: RECEIVE_CONSOLIDATE_DASHBOARD,
  RECEIVE_CONSOLIDATE_DASHBOARD_FAILED: RECEIVE_CONSOLIDATE_DASHBOARD_FAILED,
  CLEAR_GLOBAL_ITEMS_ERRORS: CLEAR_GLOBAL_ITEMS_ERRORS,
  FETCH_DEPLOYMENTS: FETCH_DEPLOYMENTS,
  RECEIVE_DEPLOYMENTS: RECEIVE_DEPLOYMENTS
};