// Merge & split
var SPLIT_ITEM = 'SPLIT_ITEM';
var RECEIVE_SPLIT_ITEM = 'RECEIVE_SPLIT_ITEM';
var RECEIVE_SPLIT_ITEM_FAILED = 'RECEIVE_SPLIT_ITEM_FAILED';
var MERGE_ITEMS = 'MERGE_ITEMS';
var RECEIVE_MERGE_ITEMS = 'RECEIVE_MERGE_ITEMS';
var RECEIVE_MERGE_ITEMS_FAILED = 'RECEIVE_MERGE_ITEMS_FAILED';
var SHOW_DIALOG = 'SHOW_DIALOG';
var CLEAR_ITEM_SPLIT_STATE = "CLEAR_ITEM_SPLIT_STATE";
var CREATE_SPLIT_ITEMS = "CREATE_SPLIT_ITEMS";
var SAVE_ITEM_CHANGES = "SAVE_ITEM_CHANGES";
export default {
  SPLIT_ITEM: SPLIT_ITEM,
  RECEIVE_SPLIT_ITEM: RECEIVE_SPLIT_ITEM,
  RECEIVE_SPLIT_ITEM_FAILED: RECEIVE_SPLIT_ITEM_FAILED,
  MERGE_ITEMS: MERGE_ITEMS,
  RECEIVE_MERGE_ITEMS: RECEIVE_MERGE_ITEMS,
  RECEIVE_MERGE_ITEMS_FAILED: RECEIVE_MERGE_ITEMS_FAILED,
  CLEAR_ITEM_SPLIT_STATE: CLEAR_ITEM_SPLIT_STATE,
  CREATE_SPLIT_ITEMS: CREATE_SPLIT_ITEMS,
  SAVE_ITEM_CHANGES: SAVE_ITEM_CHANGES,
  SHOW_DIALOG: SHOW_DIALOG
};