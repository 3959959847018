import types from "./types"; // Items

export var fetchInventoryItems = function fetchInventoryItems(params) {
  return {
    type: types.FETCH_INVENTORY_ITEMS,
    payload: params
  };
};
export var selectInventoryItem = function selectInventoryItem(params) {
  return {
    type: types.SELECT_INVENTORY_ITEM,
    payload: params
  };
};
export var unselectInventoryItem = function unselectInventoryItem(params) {
  return {
    type: types.UNSELECT_INVENTORY_ITEM,
    payload: params
  };
};
export var clearSelectedInventoryItems = function clearSelectedInventoryItems() {
  return {
    type: types.CLEAR_SELECTED_INVENTORY_ITEMS
  };
}; // Light weight inventory items

export var fetchLightWeightInventory = function fetchLightWeightInventory(params) {
  return {
    type: types.FETCH_LIGHT_WEIGHT_INVENTORY_ITEMS,
    payload: params
  };
};
export var receiveInventoryItems = function receiveInventoryItems(json) {
  if (json.inventoryType === "pending") {
    return {
      type: types.RECEIVE_PENDING_ITEMS,
      payload: json.result,
      filter: json.filterCategory
    };
  } else if (json.inventoryType === "global") {
    return {
      type: types.RECEIVE_GLOBAL_ITEMS,
      payload: json.result
    };
  } else {
    return {
      type: types.RECEIVE_INVENTORY_ITEMS,
      payload: json.result,
      filter: json.filterCategory
    };
  }
};
export var receiveInventoryItemsFailed = function receiveInventoryItemsFailed(json) {
  if (json.inventoryType === "pending") {
    return {
      type: types.RECEIVE_PENDING_ITEMS_FAILED,
      payload: json.error
    };
  } else if (json.inventoryType === "global") {
    return {
      type: types.RECEIVE_GLOBAL_ITEMS_FAILED,
      payload: json.error
    };
  } else {
    return {
      type: types.RECEIVE_INVENTORY_ITEMS_FAILED,
      payload: json.error
    };
  }
}; // Consolidate item

export var consolidateItem = function consolidateItem(params) {
  return {
    type: types.CONSOLIDATE,
    payload: params
  };
};
export var receiveConsolidateItem = function receiveConsolidateItem(json) {
  return {
    type: types.RECEIVE_CONSOLIDATE,
    payload: json
  };
};
export var receiveConsolidateItemFailed = function receiveConsolidateItemFailed(error) {
  return {
    type: types.RECEIVE_CONSOLIDATE_FAILED,
    payload: error
  };
};
export var resetConsolidate = function resetConsolidate() {
  return {
    type: types.RESET_CONSOLIDATE
  };
};
export var clearGlobalItemsErrors = function clearGlobalItemsErrors() {
  return {
    type: types.CLEAR_GLOBAL_ITEMS_ERRORS
  };
};
export var fetchDeployments = function fetchDeployments() {
  return {
    type: types.FETCH_DEPLOYMENTS
  };
};