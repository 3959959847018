// Items locations
var FETCH_LAST_LOCATIONS = 'FETCH_LAST_LOCATIONS';
var RECEIVE_LAST_LOCATIONS = 'RECEIVE_LAST_LOCATIONS';
var RECEIVE_LAST_LOCATIONS_FAILED = 'RECEIVE_LAST_LOCATIONS_FAILED';
var DELETE_ALL_LOCATIONS = 'DELETE_ALL_LOCATIONS';
var FETCH_FIRST_LOCATIONS = 'FETCH_FIRST_LOCATIONS';
var RECEIVE_FIRST_LOCATIONS = 'RECEIVE_FIRST_LOCATIONS';
export default {
  // Items locations
  FETCH_LAST_LOCATIONS: FETCH_LAST_LOCATIONS,
  RECEIVE_LAST_LOCATIONS: RECEIVE_LAST_LOCATIONS,
  RECEIVE_LAST_LOCATIONS_FAILED: RECEIVE_LAST_LOCATIONS_FAILED,
  DELETE_ALL_LOCATIONS: DELETE_ALL_LOCATIONS,
  FETCH_FIRST_LOCATIONS: FETCH_FIRST_LOCATIONS,
  RECEIVE_FIRST_LOCATIONS: RECEIVE_FIRST_LOCATIONS
};