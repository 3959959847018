var SET_TEMPLATE = 'SET_TEMPLATE';
var SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';
var RESET_TEMPLATE_DATA = 'RESET_TEMPLATE_DATA';
var SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
var SET_TAGS = 'SET_TAGS';
var ITEM_DESCRIPTION = 'ITEM_DESCRIPTION'; // Temp Data

var ADD_TEMP_DATA = 'ADD_TEMP_DATA';
var DELETE_TEMP_DATA = 'DELETE_TEMP_DATA';
var CHANGE_SFC_DATA = 'CHANGE_SFC_DATA';
var REMOVE_SFC_DATA = 'REMOVE_SFC_DATA'; // Create item

var CREATE_ITEM = 'CREATE_ITEM';
var RECEIVE_CREATE_ITEM = 'RECEIVE_CREATE_ITEM';
var RECEIVE_CREATE_ITEM_FAILED = 'RECEIVE_CREATE_ITEM_FAILED';
var RESET_STEPS = 'RESET_STEPS'; // Item Create Data

var ADD_ONE_DATA = 'ADD_ONE_DATA';
var RECEIVE_ADD_ONE_DATA = 'RECEIVE_ADD_ONE_DATA';
var RECEIVE_ADD_ONE_DATA_FAILED = 'RECEIVE_ADD_ONE_DATA_FAILED'; // Upload cover image

var UPLOAD_COVER_PHOTO = 'UPLOAD_COVER_PHOTO';
var RECEIVE_UPLOAD_COVER_PHOTO = 'RECEIVE_UPLOAD_COVER_PHOTO';
var RECEIVE_UPLOAD_COVER_PHOTO_FAILED = 'RECEIVE_UPLOAD_COVER_PHOTO_FAILED'; // Upload display image

var UPLOAD_DISPLAY_PICTURE = 'UPLOAD_DISPLAY_PICTURE';
var RECEIVE_UPLOAD_DISPLAY_PICTURE = 'RECEIVE_UPLOAD_DISPLAY_PICTURE';
var RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED = 'RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED';
var EDIT_COVER_PHOTO = 'EDIT_COVER_PHOTO';
var EDIT_DISPLAY_PICTURE = 'EDIT_DISPLAY_PICTURE';
var CLEAR_COVER_UPLOAD_ERRORS = 'CLEAR_COVER_UPLOAD_ERRORS';
var CLEAR_DISPLAY_UPLOAD_ERRORS = 'CLEAR_DISPLAY_UPLOAD_ERRORS'; //ASFIS

var FETCH_ASFIS = 'FETCH_ASFIS';
var RECEIVE_ASFIS_DATA = 'RECEIVE_ASFIS_DATA';
var RECEIVE_ASFIS_DATA_FAILED = 'RECEIVE_ASFIS_DATA_FAILED';
export default {
  SET_TEMPLATE: SET_TEMPLATE,
  SET_TEMPLATE_DATA: SET_TEMPLATE_DATA,
  RESET_TEMPLATE_DATA: RESET_TEMPLATE_DATA,
  SET_DISPLAY_NAME: SET_DISPLAY_NAME,
  SET_TAGS: SET_TAGS,
  ITEM_DESCRIPTION: ITEM_DESCRIPTION,
  ADD_TEMP_DATA: ADD_TEMP_DATA,
  DELETE_TEMP_DATA: DELETE_TEMP_DATA,
  CHANGE_SFC_DATA: CHANGE_SFC_DATA,
  REMOVE_SFC_DATA: REMOVE_SFC_DATA,
  CREATE_ITEM: CREATE_ITEM,
  RECEIVE_CREATE_ITEM: RECEIVE_CREATE_ITEM,
  RECEIVE_CREATE_ITEM_FAILED: RECEIVE_CREATE_ITEM_FAILED,
  RESET_STEPS: RESET_STEPS,
  ADD_ONE_DATA: ADD_ONE_DATA,
  RECEIVE_ADD_ONE_DATA: RECEIVE_ADD_ONE_DATA,
  RECEIVE_ADD_ONE_DATA_FAILED: RECEIVE_ADD_ONE_DATA_FAILED,
  UPLOAD_COVER_PHOTO: UPLOAD_COVER_PHOTO,
  RECEIVE_UPLOAD_COVER_PHOTO: RECEIVE_UPLOAD_COVER_PHOTO,
  RECEIVE_UPLOAD_COVER_PHOTO_FAILED: RECEIVE_UPLOAD_COVER_PHOTO_FAILED,
  UPLOAD_DISPLAY_PICTURE: UPLOAD_DISPLAY_PICTURE,
  RECEIVE_UPLOAD_DISPLAY_PICTURE: RECEIVE_UPLOAD_DISPLAY_PICTURE,
  RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED: RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED,
  EDIT_COVER_PHOTO: EDIT_COVER_PHOTO,
  EDIT_DISPLAY_PICTURE: EDIT_DISPLAY_PICTURE,
  CLEAR_COVER_UPLOAD_ERRORS: CLEAR_COVER_UPLOAD_ERRORS,
  CLEAR_DISPLAY_UPLOAD_ERRORS: CLEAR_DISPLAY_UPLOAD_ERRORS,
  FETCH_ASFIS: FETCH_ASFIS,
  RECEIVE_ASFIS_DATA: RECEIVE_ASFIS_DATA,
  RECEIVE_ASFIS_DATA_FAILED: RECEIVE_ASFIS_DATA_FAILED
};